import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import syncMiddleware from './lib/store/sync-middleware';
import flyoutMiddleware from './lib/store/flyout-middleware';
import { deepMerge, filterObject } from './lib/utils';
import { IAction, IStore } from './types';
import type { DeepPartial } from 'utility-types';
import type { IMedia, ISocialMediaProfiles } from '@dogado/webcard-shared';

export enum Reseller {
    DOGADO = 'dogado',
    FREENET = 'freenet'
}

export const initialState: IStore = {
    locked: false,
    synced: false,
    logoModalOpen: false,
    teaserModalOpen: false,
    onboarding: {
        formIsValid: false
    },
    legalHintNoted: false,
    templates: {},
    media: [],
    mediaCategories: [],
    tags: [],
    content: {},
    device: 'desktop',
    openSaveRequests: 0,
    templateData: {}
};

export const reducer = (state: IStore | undefined, action: IAction): IStore => {
    if (undefined === state) {
        state = initialState;
    }

    switch (action.type) {
        case 'SET':
            return {
                ...state,
                [action.key]: action.value
            };
        case 'RESET_TEMPLATE_DATA':
            return {
                ...state,
                templateData: {}
            };
        case 'INCREMENT_OPEN_SAVE_REQUESTS':
            return {
                ...state,
                openSaveRequests: state.openSaveRequests + 1
            };
        case 'DECREMENT_OPEN_SAVE_REQUESTS':
            return {
                ...state,
                openSaveRequests: state.openSaveRequests - 1
            };
        case 'SET_LAST_SYNC':
            return {
                ...state,
                lastSync: new Date()
            };
        case 'SET_LAST_SYNC_ERROR':
            return {
                ...state,
                lastSyncError: new Date()
            };
        case 'SET_PROPERTY':
            if (state.website) {
                return {
                    ...state,
                    website: {
                        ...state.website,
                        properties: {
                            ...(state.website.properties || {}),
                            [action.key]: action.value
                        }
                    }
                };
            } else {
                return state;
            }

        case 'TOGGLE_FLYOUT':
            return {
                ...state,
                activeFlyout: state.activeFlyout === action.key ? undefined : action.key,
                lastFlyoutInteraction:
                    state.activeFlyout === action.key
                        ? undefined
                        : {
                              flyout: action.key
                          }
            };
        case 'SET_ACTIVE_FLYOUT':
            return {
                ...state,
                activeFlyout: action.key,
                lastFlyoutInteraction: {
                    flyout: action.key
                }
            };
        case 'SET_ACTIVE_SUBFLYOUT':
            return {
                ...state,
                activeSubFlyout: action.key,
                lastFlyoutInteraction: {
                    flyout: state.activeFlyout,
                    subFlyout: action.key
                }
            };
        case 'SET_ONBOARDING_FORM_IS_VALID':
            return {
                ...state,
                onboarding: {
                    ...state.onboarding,
                    formIsValid: action.valid
                }
            };
        case 'SET_TITLE':
            if (state.website) {
                return {
                    ...state,
                    website: {
                        ...state.website,
                        title: action.title
                    }
                };
            } else {
                return state;
            }
        case 'SET_TEMPLATE_DATA_TITLE':
            return {
                ...state,
                templateData: deepMerge<DeepPartial<IStore>>(state.templateData, {
                    website: {
                        title: action.title
                    }
                })
            };
        case 'SET_INDUSTRY':
            if (state.website) {
                return {
                    ...state,
                    website: {
                        ...state.website,
                        industry: action.industry
                    }
                };
            } else {
                return state;
            }
        case 'SET_FONT':
            if (state.website) {
                return {
                    ...state,
                    website: {
                        ...state.website,
                        font: action.font
                    }
                };
            } else {
                return state;
            }
        case 'SET_LOGO':
            if (state.website) {
                return {
                    ...state,
                    website: {
                        ...state.website,
                        logo: action.logo
                    }
                };
            } else {
                return state;
            }
        case 'SET_TEASER':
            if (state.website) {
                return {
                    ...state,
                    website: {
                        ...state.website,
                        teaser: action.teaser
                    }
                };
            } else {
                return state;
            }
        case 'SET_LOGO_ALT':
            if (state.website) {
                return {
                    ...state,
                    website: {
                        ...state.website,
                        logoAlt: action.logoAlt
                    }
                };
            } else {
                return state;
            }
        case 'SET_TEMPLATE_DATA_PRIMARY_COLOR':
            return {
                ...state,
                templateData: deepMerge<DeepPartial<IStore>>(state.templateData, {
                    website: {
                        primaryColor: action.color
                    }
                })
            };
        case 'SET_TEMPLATE_DATA_BACKGROUND_COLOR':
            return {
                ...state,
                templateData: deepMerge<DeepPartial<IStore>>(state.templateData, {
                    website: {
                        backgroundColor: action.color
                    }
                })
            };
        case 'SET_DESCRIPTION':
            if (state.website) {
                return {
                    ...state,
                    website: {
                        ...state.website,
                        description: action.description
                    }
                };
            } else {
                return state;
            }
        case 'SET_TEMPLATE_DATA_DESCRIPTION':
            return {
                ...state,
                templateData: deepMerge<DeepPartial<IStore>>(state.templateData, {
                    website: {
                        description: action.description
                    }
                })
            };
        case 'SET_TEMPLATE':
            if (state.website) {
                return {
                    ...state,
                    website: {
                        ...state.website,
                        template: action.template
                    }
                };
            } else {
                return state;
            }
        case 'SET_SITE_NOTICE':
            return {
                ...state,
                siteNotice: {
                    ...state.siteNotice,
                    ...action.siteNotice
                }
            };
        case 'SET_PRIVACY_POLICY':
            return {
                ...state,
                privacyPolicy: {
                    ...state.privacyPolicy,
                    ...action.privacyPolicy
                }
            };
        case 'SET_SITE_NOTICE_CONTENT':
            return {
                ...state,
                siteNotice: {
                    ...state.siteNotice,
                    content: action.content
                }
            };
        case 'SET_PRIVACY_POLICY_CONTENT':
            return {
                ...state,
                privacyPolicy: {
                    ...state.privacyPolicy,
                    content: action.content
                }
            };
        case 'SET_WEBSITE':
            return {
                ...state,
                website: action.website
            };
        case 'SET_TAG_TITLE':
            if (state.website) {
                return {
                    ...state,
                    website: {
                        ...state.website,
                        tagTitle: action.tagTitle
                    }
                };
            } else {
                return state;
            }
        case 'SET_TEMPLATE_DATA_TAG_TITLE':
            return {
                ...state,
                templateData: deepMerge<DeepPartial<IStore>>(state.templateData, {
                    website: {
                        tagTitle: action.tagTitle
                    }
                })
            };
        case 'SET_TEMPLATE_DATA_WEBSITE':
            return {
                ...state,
                templateData: deepMerge<DeepPartial<IStore>>(state.templateData, {
                    website: action.website
                })
            };
        case 'SET_MEDIA_CATEGORIES':
            return {
                ...state,
                mediaCategories: action.mediaCategories
            };
        case 'SET_MEDIA':
            return {
                ...state,
                media: action.media
            };
        case 'ADD_MEDIA':
            return {
                ...state,
                media: [...state.media, action.media]
            };
        case 'REMOVE_MEDIA':
            return {
                ...state,
                media: state.media.filter((media: IMedia) => action.media.id !== media.id)
            };
        case 'SET_TAGS':
            return {
                ...state,
                tags: action.tags
            };
        case 'REPLACE_TAG':
            return {
                ...state,
                tags: state.tags.map((tag) => (tag.title === action.tag.title ? action.tag : tag))
            };
        case 'ADD_TAG':
            return {
                ...state,
                tags: [...state.tags, action.tag]
            };
        case 'REMOVE_TAG':
            return {
                ...state,
                tags: state.tags.filter((tag) => action.tag.title !== tag.title)
            };
        case 'TOGGLE_CONTENT':
            return {
                ...state,
                content: {
                    ...state.content,
                    [action.key]: {
                        ...state.content[action.key],
                        enabled: !state.content[action.key]?.enabled
                    }
                },
                lastFlyoutInteraction: {
                    flyout: state.activeFlyout,
                    subFlyout: action.key as string
                }
            };
        case 'SET_OPENING_HOURS':
            return {
                ...state,
                content: {
                    ...state.content,
                    openingHours: {
                        ...state.content.openingHours,
                        [action.day]: action.openingHours
                    }
                }
            };
        case 'SET_TEMPLATE_DATA_OPENING_HOURS':
            return {
                ...state,
                templateData: deepMerge<DeepPartial<IStore>>(state.templateData, {
                    content: {
                        openingHours: {
                            [action.day]: action.openingHours
                        }
                    }
                })
            };
        case 'SET_PRIMARY_COLOR':
            if (state.website) {
                return {
                    ...state,
                    website: {
                        ...state.website,
                        primaryColor: action.color
                    }
                };
            } else {
                return state;
            }
        case 'SET_BACKGROUND_COLOR':
            if (state.website) {
                return {
                    ...state,
                    website: {
                        ...state.website,
                        backgroundColor: action.color
                    }
                };
            } else {
                return state;
            }
        case 'SET_DOMAIN':
            return {
                ...state,
                domain: action.domain
            };
        case 'SET_CONTENT':
            return {
                ...state,
                content: {
                    ...state.content,
                    [action.contentType]: {
                        ...state.content[action.contentType],
                        ...action.content
                    }
                }
            };
        case 'SET_TEMPLATE_DATA_CONTENT':
            return {
                ...state,
                templateData: deepMerge<DeepPartial<IStore>>(state.templateData, {
                    content: {
                        [action.contentType]: action.content
                    }
                })
            };
        case 'SET_SOCIAL_MEDIA_URL': {
            return {
                ...state,
                content: {
                    ...state.content,
                    socialMedia: {
                        ...state.content.socialMedia,
                        profiles: filterObject({
                            ...state.content.socialMedia?.profiles,
                            [action.name]: action.url
                        }) as ISocialMediaProfiles
                    }
                }
            };
        }
        case 'SET_TEMPLATE_DATA_SOCIAL_MEDIA_URL': {
            return {
                ...state,
                templateData: deepMerge<DeepPartial<IStore>>(state.templateData, {
                    content: {
                        socialMedia: {
                            profiles: {
                                [action.name]: action.url
                            }
                        }
                    }
                })
            };
        }
        case 'TOGGLE_LOGO_MODAL_OPEN':
            return {
                ...state,
                logoModalOpen: !state.logoModalOpen
            };
        case 'TOGGLE_TEASER_MODAL_OPEN':
            return {
                ...state,
                teaserModalOpen: !state.teaserModalOpen
            };
        default:
            return state;
    }
};

const middleware = applyMiddleware(flyoutMiddleware(), syncMiddleware());

export const initializeStore = (preloadedState = initialState) => {
    return createStore(reducer, preloadedState, process.env.NODE_ENV === 'development' ? composeWithDevTools(middleware) : middleware);
};
