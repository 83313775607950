import equal from 'fast-deep-equal';
import clone from 'clone-deep';
import { updateWebsite, updateContent, updateSiteNotice, addTag, removeTag, removeMedia, updatePrivacyPolicy } from '../api';
import PromiseQueue from 'p-queue';
import { debounce } from 'throttle-debounce';
import { getOrInitializeStore } from '../redux';
import uniqid from 'uniqid';
import { Dispatch, Middleware } from 'redux';
import { IAction, IStore } from '../../types';
import { IApiResponse, IPartialContentCollection } from '@dogado/webcard-shared';

const queue = new PromiseQueue({ concurrency: 1 });

const lastSync: Record<string, string> = {};

const sync = async <T>(
    task: () => Promise<T>,
    onSuccess: (result: T) => Promise<unknown>,
    onFailure: () => Promise<unknown>,
    key?: string
) => {
    let store = getOrInitializeStore();
    let id = uniqid();

    let dispatch = (action: IAction) => {
        store.dispatch({
            noSync: true,
            ...action
        });
    };

    dispatch({
        type: 'INCREMENT_OPEN_SAVE_REQUESTS'
    });

    if (key) {
        lastSync[key] = id;
    }

    try {
        let result = await queue.add(task);

        dispatch({
            type: 'SET_LAST_SYNC'
        });

        // Only apply the result of the last request.
        if (undefined === key || lastSync[key] === id) {
            await onSuccess(result);
        }
    } catch {
        dispatch({
            type: 'SET_LAST_SYNC_ERROR'
        });

        await onFailure();
    }

    dispatch({
        type: 'DECREMENT_OPEN_SAVE_REQUESTS'
    });
};

const websiteUpdateUpdatedAt = debounce(1000, () => {
    let store = getOrInitializeStore();

    sync(
        () => updateWebsite({}),
        async (result) => {
            let data = result?.data;

            if (data) {
                store.dispatch({
                    type: 'SET_WEBSITE',
                    website: data,
                    noSync: true
                });
            }
        },
        async () => {},
        'website'
    );
});

export default function syncMiddleware(): Middleware<{}, IStore, Dispatch<IAction>> {
    return ({ getState, dispatch: baseDispatch }) => (next) => (action) => {
        let dispatch = (action: IAction) =>
            baseDispatch({
                noSync: true,
                ...action
            });

        let before = clone(getState());
        let response = next(action);

        if (action.noSync) {
            return response;
        }

        let after = clone(getState());
        let updateUpdatedAt = false;

        // Update content
        if (false === equal(before.content, after.content)) {
            let contentTypes: Array<keyof IPartialContentCollection> = ['socialMedia', 'contact', 'gallery', 'directions', 'openingHours'];

            for (let i = 0; i < contentTypes.length; i++) {
                let contentType = contentTypes[i];

                if (false === equal(before.content[contentType], after.content[contentType])) {
                    updateUpdatedAt = true;

                    sync(
                        () =>
                            updateContent({
                                type: contentType,
                                ...after.content[contentType]
                            }),
                        async (result) => {
                            dispatch({
                                type: 'SET_CONTENT',
                                contentType,
                                content: result.data
                            });
                        },
                        async () => {
                            dispatch({
                                type: 'SET_CONTENT',
                                contentType,
                                content: before.content[contentType]
                            });
                        },
                        contentType
                    );
                }
            }
        }

        // Update site notice
        const beforeSiteNotice = before.siteNotice;
        const afterSiteNotice = after.siteNotice;

        if (false === equal(beforeSiteNotice, afterSiteNotice)) {
            updateUpdatedAt = true;

            sync<IApiResponse>(
                async () =>
                    afterSiteNotice
                        ? updateSiteNotice({
                              content: '',
                              ...afterSiteNotice
                          })
                        : {},
                async (result) => {
                    let siteNotice = result.data ?? beforeSiteNotice;

                    if (siteNotice) {
                        dispatch({
                            type: 'SET_SITE_NOTICE',
                            siteNotice
                        });
                    }
                },
                async () => {
                    if (beforeSiteNotice) {
                        dispatch({
                            type: 'SET_SITE_NOTICE',
                            siteNotice: beforeSiteNotice
                        });
                    }
                },
                'siteNotice'
            );
        }

        // Update privacy policy
        const beforePrivacyPolicy = before.privacyPolicy;
        const afterPrivacyPolicy = after.privacyPolicy;

        if (false === equal(beforePrivacyPolicy, afterPrivacyPolicy)) {
            updateUpdatedAt = true;

            sync<IApiResponse>(
                async () =>
                    afterPrivacyPolicy
                        ? updatePrivacyPolicy({
                              content: '',
                              ...afterPrivacyPolicy
                          })
                        : {},
                async (result) => {
                    let privacyPolicy = result.data ?? beforePrivacyPolicy;

                    if (privacyPolicy) {
                        dispatch({
                            type: 'SET_PRIVACY_POLICY',
                            privacyPolicy
                        });
                    }
                },
                async () => {
                    if (beforePrivacyPolicy) {
                        dispatch({
                            type: 'SET_PRIVACY_POLICY',
                            privacyPolicy: beforePrivacyPolicy
                        });
                    }
                },
                'privacyPolicy'
            );
        }

        // Update Tags
        if (false === equal(before.tags, after.tags)) {
            // Add new Tags
            if (after.tags) {
                for (let i = 0; i < after.tags.length; i++) {
                    let tag = after.tags[i];

                    if (false === before.tags.some((v) => v.title === tag.title)) {
                        updateUpdatedAt = true;

                        sync(
                            () => addTag(tag),
                            async (result) => {
                                let tag = result.data;

                                if (tag) {
                                    dispatch({
                                        type: 'REPLACE_TAG',
                                        tag
                                    });
                                }
                            },
                            async () => {
                                dispatch({
                                    type: 'REMOVE_TAG',
                                    tag: tag
                                });
                            }
                        );
                    }
                }
            }

            // Remove tags
            if (before.tags) {
                for (let i = 0; i < before.tags.length; i++) {
                    let tag = before.tags[i];

                    if (false === after.tags.some((v) => v.title === tag.title)) {
                        updateUpdatedAt = true;

                        const id = tag.id;

                        sync<IApiResponse>(
                            async () => (id ? removeTag({ id }) : {}),
                            async () => {},
                            async () => {
                                dispatch({
                                    type: 'ADD_TAG',
                                    tag: tag
                                });
                            }
                        );
                    }
                }
            }
        }

        // Remove media
        if (false === equal(before.media, after.media)) {
            if (before.media) {
                for (let i = 0; i < before.media.length; i++) {
                    let media = before.media[i];

                    if (false === after.media.some((v) => v.id === media.id)) {
                        updateUpdatedAt = true;

                        sync(
                            () => removeMedia(media),
                            async () => {},
                            async () => {
                                dispatch({
                                    type: 'ADD_MEDIA',
                                    media: media
                                });
                            }
                        );
                    }
                }
            }
        }

        // Update website data
        const beforeWebsite = before.website;
        const afterWebsite = after.website;

        if (false === equal(beforeWebsite, afterWebsite)) {
            sync<IApiResponse>(
                async () => (afterWebsite ? updateWebsite(afterWebsite) : {}),
                async (result) => {
                    let website = result.data || beforeWebsite;

                    if (website) {
                        dispatch({
                            type: 'SET_WEBSITE',
                            website
                        });
                    }
                },
                async () => {
                    if (beforeWebsite) {
                        dispatch({
                            type: 'SET_WEBSITE',
                            website: beforeWebsite
                        });
                    }
                },
                'website'
            );
        } else if (updateUpdatedAt) {
            websiteUpdateUpdatedAt();
        }

        return response;
    };
}
