import { useEffect, useState, useLayoutEffect, RefObject, useMemo } from 'react';
import {
    getWebsite,
    getContent,
    getMedia,
    getMediaCategories,
    getSiteNotice,
    getTags,
    getDomain,
    getTemplate,
    getTemplates,
    getPrivacyPolicy,
    getPing
} from '../lib/api';
import { batch, useDispatch, useSelector } from 'react-redux';
import { lcfirst, canUseDom, isPreviewPage } from './utils';
import { IStore } from '../types';
import { IDomain, IGetWebsiteResponse, ITemplate, url } from '@dogado/webcard-shared';
import { getResellerByHost } from '@dogado/webcard-shared/dist/reseller';
import { getConfigByReseller } from '@dogado/webcard-shared/dist/config';

/**
 * @see https://github.com/reach/reach-ui/blob/master/packages/utils/src/index.tsx#L26
 */
export const useIsomorphicLayoutEffect = canUseDom() ? useLayoutEffect : useEffect;

export function useOnClickOutside(ref: RefObject<Element | undefined>, handler: (event: MouseEvent | TouchEvent) => unknown) {
    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            let target = event.target;

            if (target && ref.current?.contains(target as Element)) {
                return;
            }

            handler(event);
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
}

export function useServerState() {
    let isSynced = useSelector<IStore, boolean>((state) => state.synced);
    let templateName = useSelector<IStore, string | undefined | null>((state) => state.website?.template);
    let currentDomain = useSelector<IStore, IDomain | undefined>((state) => state.domain);
    let template = useSelector<IStore, ITemplate | undefined>((state) => state.template);
    let dispatch = useDispatch();
    let [isLoading, setIsLoading] = useState(false === isSynced);

    useEffect(() => {
        if (isSynced) {
            return;
        }

        (async () => {
            let website = await getWebsite();

            if (undefined === website?.data?.uid) {
                return;
            }

            let [templates, domain, media, mediaCategories, siteNotice, privacyPolicy, content, tags] = await Promise.all([
                getTemplates(),
                getDomain(),
                getMedia(),
                getMediaCategories(),
                getSiteNotice(),
                getPrivacyPolicy(),
                getContent(),
                getTags()
            ]);

            batch(() => {
                dispatch({
                    type: 'SET',
                    key: 'templates',
                    value: templates?.data || {},
                    noSync: true
                });
                dispatch({
                    type: 'SET_WEBSITE',
                    website: website?.data || {},
                    noSync: true
                });
                dispatch({
                    type: 'SET_DOMAIN',
                    domain: domain?.data || {},
                    noSync: true
                });
                dispatch({
                    type: 'SET_MEDIA',
                    media: media?.data || [],
                    noSync: true
                });
                dispatch({
                    type: 'SET_MEDIA_CATEGORIES',
                    mediaCategories: mediaCategories?.data || [],
                    noSync: true
                });
                dispatch({
                    type: 'SET_SITE_NOTICE',
                    siteNotice: siteNotice?.data || {},
                    noSync: true
                });
                dispatch({
                    type: 'SET_PRIVACY_POLICY',
                    privacyPolicy: privacyPolicy?.data || {},
                    noSync: true
                });
                if (content.data) {
                    content.data.forEach((c) => {
                        dispatch({
                            type: 'SET_CONTENT',
                            contentType: lcfirst(c.type),
                            content: c,
                            noSync: true
                        });
                    });
                }
                dispatch({
                    type: 'SET_TAGS',
                    tags: tags?.data || [],
                    noSync: true
                });
                dispatch({
                    type: 'SET',
                    key: 'synced',
                    value: true,
                    noSync: true
                });
            });
        })();
    }, [isSynced]);

    useEffect(() => {
        // Template does not need to be reloaded if known template and supposedly new template are identical.
        if (!templateName || templateName === template?.id) {
            return;
        }

        (async () => {
            let templateData = (await getTemplate({ id: templateName }))?.data;

            dispatch({
                type: 'SET',
                key: 'template',
                value: templateData
            });
        })();
    }, [templateName]);

    useEffect(() => {
        setIsLoading(false === isSynced);
    }, [isSynced]);

    useEffect(() => {
        if (!currentDomain) {
            let interval = setInterval(async () => {
                let domain = await getDomain();

                dispatch({
                    type: 'SET_DOMAIN',
                    domain: domain.data,
                    noSync: true
                });
            }, 60 * 1000);

            return () => {
                clearInterval(interval);
            };
        }

        return () => {};
    }, [currentDomain]);

    useEffect(() => {
        let interval = setInterval(async () => {
            if (false === isPreviewPage()) {
                await getPing();
            }
        }, 5 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return { isLoading };
}

export function useHost() {
    let href: string | undefined;

    if (typeof window !== 'undefined') {
        href = window.location.href;
    }

    return useMemo(() => {
        if (typeof window === 'undefined') {
            return undefined;
        }

        return url.getHost(window.location.href);
    }, [href]);
}

export function useReseller() {
    let host = useHost();
    let website = useSelector<IStore, IGetWebsiteResponse | undefined>((state) => state.website);

    return useMemo(() => {
        if (website) {
            return website.reseller;
        }

        return host ? getResellerByHost(host) : undefined;
    }, [host, website]);
}

export function useApiUrl() {
    let host = useHost();

    return useMemo(() => (host ? url.getApiUrl(host) : undefined), [host]);
}

export function useStaticUrl() {
    let host = useHost();

    return useMemo(() => (host ? url.getStaticUrl(host) : undefined), [host]);
}

export function useConfig() {
    let reseller = useReseller();

    return useMemo(() => getConfigByReseller(reseller), [reseller]);
}

export function useDomain(): string | null {
    let domain = useSelector<IStore, string | undefined>((state) => state.domain?.name);
    let subdomain = useSelector<IStore, string | undefined | null>((state) => state.website?.subdomain);

    let config = useConfig();

    if (domain) {
        return domain;
    }

    if (subdomain && config.subdomainHost) {
        return `${subdomain}.${config.subdomainHost}`;
    }

    return null;
}

export function useUpsellUrl(): string | null {
    let domain = useSelector<IStore, string | undefined>((state) => state.domain?.name);
    let website = useSelector<IStore, IGetWebsiteResponse | undefined>((state) => state.website);

    let config = useConfig();

    if (!domain && website?.subdomain) {
        let url = new URL(config.urls.orderSystem);

        url.searchParams.append('uuid', website.uid);

        return url.toString();
    }

    return null;
}
