"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getResellerByHost = exports.Reseller = void 0;
const config_1 = require("./config");
var Reseller;
(function (Reseller) {
    Reseller["DOGADO"] = "dogado";
    Reseller["FREENET"] = "freenet";
    Reseller["DOGADO_EMPLOYEE"] = "dogado_employee";
})(Reseller = exports.Reseller || (exports.Reseller = {}));
function getResellerByHost(host) {
    let config = config_1.getConfig();
    let reseller = Object.keys(config).find((key) => {
        return (config[key].hosts || []).includes(host);
    });
    if (reseller === 'default' || undefined === reseller) {
        reseller = Reseller.DOGADO;
    }
    return reseller;
}
exports.getResellerByHost = getResellerByHost;
