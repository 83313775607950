"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable @typescript-eslint/camelcase */
const simple_icons_1 = require("simple-icons");
const config = {
    default: {
        hosts: [],
        product: {
            name: 'dogado Webcard'
        },
        tracking: {
            gtag: {
                enabled: false
            },
            hotjar: {
                enabled: true,
                trackingId: '1961907'
            },
            consent: {
                enabled: false
            }
        },
        features: {
            siteNotice: true,
            privacyPolicy: true
        },
        fonts: {
            serif: [
                { key: 'cormorant', name: 'Cormorant' },
                { key: 'inknut_antiqua', name: 'Inknut Antiqua' },
                { key: 'cormorant_garamon', name: 'Cormorant Garamon' },
                { key: 'source_serif_pro', name: 'Source Serif Pro' },
                { key: 'playfair_display', name: 'Playfair Display' },
                { key: 'martel', name: 'Martel' }
            ],
            sans_serif: [
                { key: 'work_sans', name: 'Work Sans' },
                { key: 'libre_franklin', name: 'Libre Franklin' },
                { key: 'montserrat', name: 'Montserrat' },
                { key: 'raleway', name: 'Raleway' },
                { key: 'open_sans', name: 'Open Sans' },
                { key: 'noto_sans_jp', name: 'Noto Sans JP' }
            ],
            schreibschrift: [
                { key: 'indie_flower', name: 'Indie Flower' },
                { key: 'unica_one', name: 'Unica One' },
                { key: 'shadows_into_light', name: 'Shadows Into Light' },
                { key: 'dancing_script', name: 'Dancing Script' }
            ],
            display: [
                { key: 'slabo_13px', name: 'Slabo 13px' },
                { key: 'permanent_marker', name: 'Permanent Marker' },
                { key: 'abril_fatface', name: 'Abril Fatface' },
                { key: 'jacques_francois_shadow', name: 'Jacques Francois Shadow' },
                { key: 'bebas_neue', name: 'Bebas Neue' }
            ],
            technical: [
                { key: 'space_mono', name: 'Space Mono' },
                { key: 'anonymous_pro', name: 'Anonymous Pro' },
                { key: 'share_tech_mono', name: 'Share Tech Mono' },
                { key: 'vt323', name: 'VT323' }
            ]
        },
        ui: {
            colors: {
                primary: '#fec600',
                primaryDark: '#e4b200',
                primaryContrast: '#22314D'
            }
        },
        colors: {
            backgroundSuggestions: ['#000000', '#ffffff', '#EBEBEB', '#F5F5F5', '#F5EBEB', '#F5EBF2', '#F2EBF5', '#EBEBF5'],
            primarySuggestions: [
                '#000000',
                '#ffffff',
                '#EBEBEB',
                '#F5F5F5',
                '#F58989',
                '#F589D1',
                '#D189F5',
                '#8989F5',
                '#89D1F5',
                '#89F5F4',
                '#89F5B2',
                '#F5F589',
                '#F5D189',
                '#F5AD89'
            ]
        },
        socialMedia: {
            facebook: {
                title: simple_icons_1.get('facebook').title,
                url: {
                    domain: ['www.facebook.com']
                },
                icon: {
                    path: simple_icons_1.get('facebook').path
                }
            },
            instagram: {
                title: simple_icons_1.get('instagram').title,
                url: {
                    domain: ['www.instagram.com']
                },
                icon: {
                    path: simple_icons_1.get('instagram').path
                }
            },
            twitter: {
                title: simple_icons_1.get('twitter').title,
                url: {
                    domain: ['twitter.com']
                },
                icon: {
                    path: simple_icons_1.get('twitter').path
                }
            },
            linkedin: {
                title: simple_icons_1.get('linkedin').title,
                url: {
                    domain: ['www.linkedin.com']
                },
                icon: {
                    path: simple_icons_1.get('linkedin').path
                }
            },
            pinterest: {
                title: simple_icons_1.get('pinterest').title,
                url: {
                    domain: ['www.pinterest.com']
                },
                icon: {
                    path: simple_icons_1.get('pinterest').path
                }
            },
            xing: {
                title: simple_icons_1.get('xing').title,
                url: {
                    domain: ['www.xing.com']
                },
                icon: {
                    path: simple_icons_1.get('xing').path
                }
            },
            dribbble: {
                title: simple_icons_1.get('dribbble').title,
                url: {
                    domain: ['dribbble.com']
                },
                icon: {
                    path: simple_icons_1.get('dribbble').path
                }
            },
            github: {
                title: simple_icons_1.get('github').title,
                url: {
                    domain: ['github.com']
                },
                icon: {
                    path: simple_icons_1.get('github').path
                }
            },
            discord: {
                title: simple_icons_1.get('discord').title,
                url: {
                    domain: ['discord.com']
                },
                icon: {
                    path: simple_icons_1.get('discord').path
                }
            },
            vimeo: {
                title: simple_icons_1.get('vimeo').title,
                url: {
                    domain: ['vimeo.com']
                },
                icon: {
                    path: simple_icons_1.get('vimeo').path
                }
            },
            flickr: {
                title: simple_icons_1.get('flickr').title,
                url: {
                    domain: ['www.flickr.com']
                },
                icon: {
                    path: simple_icons_1.get('flickr').path
                }
            },
            behance: {
                title: simple_icons_1.get('behance').title,
                url: {
                    domain: ['www.behance.com']
                },
                icon: {
                    path: simple_icons_1.get('behance').path
                }
            },
            medium: {
                title: simple_icons_1.get('medium').title,
                url: {
                    domain: ['medium.com']
                },
                icon: {
                    path: simple_icons_1.get('medium').path
                }
            },
            tumblr: {
                title: simple_icons_1.get('tumblr').title,
                url: {
                    domain: ['www.tumblr.com']
                },
                icon: {
                    path: simple_icons_1.get('tumblr').path
                }
            },
            youtube: {
                title: simple_icons_1.get('youtube').title,
                url: {
                    domain: ['www.youtube.com']
                },
                icon: {
                    path: simple_icons_1.get('youtube').path
                }
            },
            tiktok: {
                title: simple_icons_1.get('tiktok').title,
                url: {
                    domain: ['www.tiktok.com']
                },
                icon: {
                    path: simple_icons_1.get('tiktok').path
                }
            }
        },
        urls: {
            controlPanel: 'https://www.cloudpit.io',
            orderSystem: 'https://buy.dogado.de/products/webhosting?chooseProduct=286',
            landingPage: 'https://www.dogado.de/website/webcard'
        },
        onboarding: {
            welcome: {
                enabled: true
            },
            title: {
                enabled: true
            },
            industry: {
                enabled: true
            },
            tag: {
                enabled: true
            },
            content: {
                enabled: true
            },
            template: {
                enabled: true
            }
        },
        contents: {
            contact: {
                enabled: true
            },
            directions: {
                enabled: true
            }
        }
    },
    dogado: {
        hosts: ['webcard.dogado.de', 'webcard.dogado.localhost']
    },
    dogado_employee: {
        hosts: ['webcard.dogado.de', 'webcard.dogado.localhost'],
        colors: {
            primarySuggestions: ['#fec600']
        },
        features: {
            siteNotice: false,
            privacyPolicy: false
        }
    },
    freenet: {
        hosts: ['web-card.freenet.de', 'webcard.freenet.localhost', 'cloudcards.cloudpit.io'],
        subdomainHost: 'freenet-webcard.de',
        product: {
            name: 'freenet Webcard'
        },
        tracking: {
            gtag: {
                enabled: true,
                trackingId: 'GTM-NG9JM5'
            },
            hotjar: {
                enabled: false
            },
            consent: {
                enabled: true
            }
        },
        ui: {
            logo: {
                src: '/logo/freenet.png',
                alt: 'freenet WEBCARD'
            },
            colors: {
                primary: '#FF2266',
                primaryDark: '#E41354',
                primaryContrast: '#fff',
                checkboxActiveBackground: '#21314d',
                checkboxActiveText: '#ffffff',
                onboardingStepHeadlineColor: '#FF2266',
                onboardingTipBackground: '#EEFAC9'
            }
        },
        urls: {
            controlPanel: 'https://webvisitenkarte.freenet.de/',
            orderSystem: 'https://registrierung.freenet.de/#/webcard/start',
            landingPage: 'https://webvisitenkarte.freenet.de/'
        }
    }
};
exports.default = config;
