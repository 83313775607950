import { Dispatch, Middleware } from 'redux';
import { IAction, IStore } from '../../types';

export default function flyoutMiddleware(): Middleware<{}, IStore, Dispatch<IAction>> {
    return ({ getState, dispatch }) => (next) => (action: IAction) => {
        let result = next(action);

        if (action.type === 'OPEN_FLYOUT') {
            let { activeFlyout, activeSubFlyout = '' } = getState();
            let { flyout, subFlyout = '' } = action;

            let actions = [];

            if (activeSubFlyout && `${activeFlyout}.${activeSubFlyout}` !== `${flyout}.${subFlyout}`) {
                actions.push(() => {
                    dispatch({
                        type: 'SET_ACTIVE_SUBFLYOUT',
                        key: undefined
                    });
                });
            }

            if (activeFlyout !== flyout) {
                actions.push(() => {
                    dispatch({
                        type: 'SET_ACTIVE_FLYOUT',
                        key: flyout
                    });
                });
            }

            if (subFlyout) {
                actions.push(() => {
                    dispatch({
                        type: 'SET_ACTIVE_SUBFLYOUT',
                        key: subFlyout
                    });
                });
            }

            actions.forEach((v, i) => {
                i === 0 ? v() : setTimeout(v, i * 300);
            });
        }

        return result;
    };
}
