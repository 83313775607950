import axios, { Method } from 'axios';
import sanitizeHtml_ from 'sanitize-html';
import { getOrInitializeStore } from './redux';
import uniqid from 'uniqid';
import deepmerge from 'deepmerge';
import { IApiResponse, IMedia, url } from '@dogado/webcard-shared';
import { DeepPartial } from 'utility-types';
import cookies from 'js-cookie';
import { getHost } from '../../shared/dist/url';
import { getResellerByHost } from '@dogado/webcard-shared/dist/reseller';
import { getConfigByReseller } from '@dogado/webcard-shared/dist/config';

export const editorId = uniqid();

export function isPreviewPage() {
    return !!window.location.pathname.match(/^\/preview/);
}

export function canUseDom() {
    return !!(typeof window !== 'undefined' && window.document && window.document.createElement);
}

export type FetchOptions = {
    method: Method;
    json?: object;
};

export function getDefaultHeaders(override: Record<string, string> = {}) {
    let csrfToken = cookies.get('_csrf');

    let headers: Record<string, string> = {
        ...override
    };

    if (false === isPreviewPage()) {
        headers['x-editor-id'] = editorId;
    }

    if (csrfToken) {
        headers['x-csrf-token'] = csrfToken;
    }

    return headers;
}

export async function fetchFromApi<T>(path: string, { json, method }: FetchOptions): Promise<IApiResponse<T>> {
    let store = getOrInitializeStore();
    let state = store.getState();
    let isTrial = !!state.website?.trial;

    if (state.locked || (method !== 'GET' && isPreviewPage())) {
        return {
            data: undefined
        };
    }

    let host = url.getHost(window.location.href);
    let apiUrl = url.getApiUrl(host);

    try {
        let res = await axios({
            url: `${apiUrl}${path}`,
            method,
            data: json,
            withCredentials: true,
            headers: getDefaultHeaders()
        });

        return res.data;
    } catch (err) {
        if (err && err.response) {
            switch (err.response.status) {
                case 401:
                case 403:
                    // also redirect 403 to handle missing/non existent CSRF cookie
                    // redirect if not authenticated
                    let host = getHost(window.location.href);
                    let reseller = host ? getResellerByHost(host) : undefined;
                    let config = getConfigByReseller(reseller);

                    if (isTrial) {
                        window.location.href = config.urls.landingPage;
                    } else {
                        window.location.href = config.urls.controlPanel;
                    }

                    return {
                        data: undefined
                    };

                case 409:
                    store.dispatch({
                        type: 'SET',
                        key: 'locked',
                        value: true
                    });

                    return {
                        data: undefined
                    };

                default:
                    throw err;
            }
        }

        console.error(err);

        return {
            data: undefined
        };
    }
}

export function shuffleArray<T>(input: T[]): T[] {
    if (!Array.isArray(input)) {
        throw new Error(`Expected array, got ${typeof input}`);
    }

    let arr = [...input];
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * i);
        const temp = arr[i];
        arr[i] = arr[j];
        arr[j] = temp;
    }

    return arr;
}

export function ucfirst(val: string) {
    return val.charAt(0).toUpperCase() + val.slice(1);
}

export function lcfirst(val: string) {
    return val.charAt(0).toLowerCase() + val.slice(1);
}

export function getTemplatePreviewImage(url: string, template: string) {
    return `${url}/templates/preview/${template}.jpg`;
}

export function sleep(time: number) {
    return new Promise((resolve) => setTimeout(() => resolve(true), time));
}

export function offset(el: Element, windowObject: Window = window) {
    var rect = el.getBoundingClientRect(),
        scrollLeft = windowObject.pageXOffset || windowObject.document.documentElement.scrollLeft,
        scrollTop = windowObject.pageYOffset || windowObject.document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft, width: rect.width, height: rect.height };
}

export function sanitizeHtml(html: string): string {
    return sanitizeHtml_(html, {
        allowedTags: [
            'h1',
            'h2',
            'h3',
            'h4',
            'h5',
            'h6',
            'blockquote',
            'p',
            'a',
            'ul',
            'ol',
            'li',
            'b',
            'i',
            'strong',
            'em',
            'strike',
            'abbr',
            'hr',
            'br',
            'table',
            'thead',
            'caption',
            'tbody',
            'tr',
            'th',
            'td'
        ]
    });
}

export function isMediaDeletable(media: IMedia) {
    let store = getOrInitializeStore();
    let state = store.getState();

    return (
        state.website?.teaser?.id !== media.id &&
        state.website?.logo?.id !== media.id &&
        false === (state.content.gallery?.media || []).some((m) => m.id === media.id) &&
        true === state.media.some((m) => m.id === media.id)
    );
}

export function deepMerge<T>(a: DeepPartial<T>, b: DeepPartial<T>): T {
    return deepmerge(a as Partial<T>, b as Partial<T>, {
        // Just replace arrays
        arrayMerge: (destinationArray, sourceArray, options) => sourceArray
    });
}

export function filterObject<T extends {}>(obj: T, predicate: (value: unknown) => unknown = (val) => val): Partial<T> {
    let result: Partial<T> = {};

    for (let key in obj) {
        if (obj.hasOwnProperty(key) && !!predicate(obj[key])) {
            result[key] = obj[key];
        }
    }

    return result;
}

export function sanitizeHTML(str: string): string {
    return str.replace(/[^\w. ]/gi, (c) => `&#${c.charCodeAt(0)};`);
}

export function isValidEpid(epid: string): boolean {
    return /e[0-9]+/.test(epid);
}
