"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStaticUrl = exports.getEditorUrl = exports.getApiUrl = exports.getHost = void 0;
function isLocalhost(host) {
    return !!host.match(/\.localhost$/);
}
function getProtocol(host) {
    return isLocalhost(host) ? 'http://' : 'https://';
}
function getHost(href) {
    let instance = new URL(href);
    return instance.hostname.replace(/^api\./, '');
}
exports.getHost = getHost;
function getApiUrl(host) {
    return `${getProtocol(host)}api.${host}`;
}
exports.getApiUrl = getApiUrl;
function getEditorUrl(host) {
    return `${getProtocol(host)}${host}`;
}
exports.getEditorUrl = getEditorUrl;
function getStaticUrl(host) {
    return `${getProtocol(host)}api.${host}/public/static`;
}
exports.getStaticUrl = getStaticUrl;
