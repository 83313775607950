import {
    IPostWebsiteContentRequest,
    IRemoveWebsiteTagIdRequest,
    IPostWebsiteTagRequest,
    IRemoveWebsiteMediaIdRequest,
    IPostWebsitePrivacyPolicyRequest,
    IPostWebsiteSiteNoticeRequest,
    IGetTemplateIdRequest,
    IPostTrialResponse,
    IPatchWebsiteRequest,
    IApiResponse,
    IWebsite,
    IGetWebsiteResponse,
    IGetAuthPingResponse,
    IPatchWebsiteResponse,
    IGetTemplateResponse,
    IGetTemplateIdResponse,
    IGetWebsiteSiteNoticeResponse,
    IGetWebsitePrivacyPolicyResponse,
    IPostWebsiteSiteNoticeResponse,
    IPostWebsitePrivacyPolicyResponse,
    IGetTagResponse,
    IPostWebsiteTagResponse,
    IGetWebsiteContentResponse,
    IPostWebsiteContentResponse,
    IGetWebsiteDomainResponse,
    IGetWebsiteMediaResponse,
    IGetWebsiteMediaCategoriesResponse,
    IPostAuthLogoutResponse,
    IPostTrialRequest,
    IGetSSOTokenResponse,
    IGetSssoTokenRequest
} from '@dogado/webcard-shared';
import { fetchFromApi, ucfirst } from './utils';

export function getPing(): Promise<IApiResponse<IGetAuthPingResponse>> {
    return fetchFromApi('/public/auth/ping', { method: 'GET' });
}

export function getWebsite(): Promise<IApiResponse<IGetWebsiteResponse>> {
    return fetchFromApi<IWebsite>('/public/website', { method: 'GET' });
}

export function updateWebsite(data: IPatchWebsiteRequest): Promise<IApiResponse<IPatchWebsiteResponse>> {
    return fetchFromApi(`/public/website`, {
        method: 'PATCH',
        json: data
    });
}

export function getTrial(data: IPostTrialRequest): Promise<IApiResponse<IPostTrialResponse>> {
    return fetchFromApi(`/public/trial`, {
        method: 'POST',
        json: data
    });
}

export function getTemplates(): Promise<IApiResponse<IGetTemplateResponse>> {
    return fetchFromApi(`/public/template`, {
        method: 'GET'
    });
}

export function getTemplate({ id }: IGetTemplateIdRequest): Promise<IApiResponse<IGetTemplateIdResponse>> {
    return fetchFromApi(`/public/template/${id}`, {
        method: 'GET'
    });
}

export async function getSiteNotice(): Promise<IApiResponse<IGetWebsiteSiteNoticeResponse>> {
    try {
        return await fetchFromApi('/public/website/site-notice', { method: 'GET' });
    } catch {
        return Promise.resolve({});
    }
}

export function updateSiteNotice(data: IPostWebsiteSiteNoticeRequest): Promise<IApiResponse<IPostWebsiteSiteNoticeResponse>> {
    return fetchFromApi(`/public/website/site-notice`, {
        method: 'POST',
        json: data
    });
}

export async function getPrivacyPolicy(): Promise<IApiResponse<IGetWebsitePrivacyPolicyResponse>> {
    try {
        return await fetchFromApi('/public/website/privacy-policy', { method: 'GET' });
    } catch {
        return Promise.resolve({});
    }
}

export function updatePrivacyPolicy(data: IPostWebsitePrivacyPolicyRequest): Promise<IApiResponse<IPostWebsitePrivacyPolicyResponse>> {
    return fetchFromApi(`/public/website/privacy-policy`, {
        method: 'POST',
        json: data
    });
}

export function removeMedia({ id }: IRemoveWebsiteMediaIdRequest): Promise<IApiResponse> {
    return fetchFromApi(`/public/website/media/${id}`, {
        method: 'DELETE'
    });
}

export function getTags(): Promise<IApiResponse<IGetTagResponse>> {
    return fetchFromApi(`/public/website/tag`, { method: 'GET' });
}

export function addTag({ title }: IPostWebsiteTagRequest): Promise<IApiResponse<IPostWebsiteTagResponse>> {
    return fetchFromApi(`/public/website/tag`, {
        method: 'POST',
        json: {
            title
        }
    });
}

export function removeTag({ id }: IRemoveWebsiteTagIdRequest): Promise<IApiResponse> {
    return fetchFromApi(`/public/website/tag/${id}`, {
        method: 'DELETE'
    });
}

export function getContent(): Promise<IApiResponse<IGetWebsiteContentResponse>> {
    return fetchFromApi(`/public/website/content`, { method: 'GET' });
}

export function updateContent({
    type,
    enabled = true,
    ...data
}: IPostWebsiteContentRequest): Promise<IApiResponse<IPostWebsiteContentResponse>> {
    return fetchFromApi(`/public/website/content`, {
        method: 'POST',
        json: {
            type: ucfirst(type),
            ...data,
            enabled
        }
    });
}

export async function getDomain(): Promise<IApiResponse<IGetWebsiteDomainResponse>> {
    try {
        let res = await fetchFromApi<IGetWebsiteDomainResponse>(`/public/website/domain`, {
            method: 'GET'
        });

        return res;
    } catch (err) {
        return Promise.resolve({});
    }
}

export function getMedia(): Promise<IApiResponse<IGetWebsiteMediaResponse>> {
    return fetchFromApi(`/public/website/media`, {
        method: 'GET'
    });
}

export function getMediaCategories(): Promise<IApiResponse<IGetWebsiteMediaCategoriesResponse>> {
    return fetchFromApi(`/public/website/media/categories`, {
        method: 'GET'
    });
}

export function logout(): Promise<IApiResponse<IPostAuthLogoutResponse>> {
    return fetchFromApi('/public/auth/logout', {
        method: 'POST'
    });
}

export function getSso({ token, uid }: IGetSssoTokenRequest): Promise<IApiResponse<IGetSSOTokenResponse>> {
    return fetchFromApi(`/public/sso?token=${token}&uid=${uid}`, {
        method: 'GET'
    });
}

export function deprovisionWebsite(): Promise<IApiResponse<{}>> {
    return fetchFromApi('/public/website/deprovision', {
        method: 'POST'
    });
}
